/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, SeparateLine, SeparateLineWrap, ContactForm, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--0 pt--0" style={{"backgroundColor":"var(--color-supplementary)"}} name={"1zv03r3aiu9i"} layout={"l30"}>
        </Column>


        <Column className="css-4cbfai js-anim  --anim2" style={{"paddingTop":83,"paddingBottom":47}} anim={"2"} name={"sl2m1bszipm"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"marginTop":0,"paddingTop":6}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":750,"paddingTop":0}}>
              
              <Title className="title-box" style={{"paddingBottom":5,"paddingTop":8}} content={"<span style=\"background-color: rgb(255, 255, 255);\">Kontaktní informace</span>"}>
              </Title>

              <Subtitle className="subtitle-box lh--14" style={{"maxWidth":650,"marginTop":0,"paddingTop":3,"marginBottom":0,"paddingBottom":0}} content={"<span style=\"font-style: italic; background-color: rgb(255, 255, 255);\">Zavolejte nám, napište e-mail nebo využijte formulář.<br></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-p8abq4 --center" style={{"paddingTop":0,"paddingBottom":0,"marginBottom":0}} name={"3l456td6dbe"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37992/54f7a52aead143c28bf26a9d4675ac64_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/54f7a52aead143c28bf26a9d4675ac64_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37992/54f7a52aead143c28bf26a9d4675ac64_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/54f7a52aead143c28bf26a9d4675ac64_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37992/54f7a52aead143c28bf26a9d4675ac64_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37992/54f7a52aead143c28bf26a9d4675ac64_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/54f7a52aead143c28bf26a9d4675ac64_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/54f7a52aead143c28bf26a9d4675ac64_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --center el--1" style={{"paddingTop":0,"marginTop":21}} anim={"2"} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":850,"backgroundColor":"var(--white)","paddingBottom":3,"marginBottom":0}}>
              
              <Subtitle className="subtitle-box subtitle-box--center fs--30 lh--14" style={{"maxWidth":1350,"paddingLeft":0,"paddingRight":0,"paddingBottom":5,"marginBottom":28}} content={"<span style=\"background-color: rgb(255, 255, 255);\"><span style=\"color: var(--color-blend--15);\">ADF | agentura divadelní fantazie</span><br><a href=\"mailto:adf@adf.cz?subject=Zpr%C3%A1va+z+webu&amp;bcc=marek.sucharda%40gmail.com\">adf@adf.cz</a><br><a href=\"/\">www.adf.cz</a></span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --center el--2 flex--center" style={{"maxWidth":850,"marginTop":0,"backgroundColor":"var(--white)"}} columns={"2"} anim={"2"}>
            
            <ColumnWrapper className="--left" style={{"backgroundColor":"var(--white)"}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":394,"paddingRight":0,"paddingLeft":0}} content={"<span style=\"color: var(--color-blend--15);\">Kateřina Schauerová\n<br>+420 603 219 657\n</span><br><a href=\"mailto:katerina@adf.cz?subject=Zpr%C3%A1va+z+webu\">katerina@adf.cz</a><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"var(--white)"}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":650,"paddingLeft":0,"paddingRight":0}} content={"<span style=\"color: var(--color-blend--15);\">Marek Sucharda<br>+420 775 262 617</span><br><a href=\"mailto:marek@adf.cz?subject=Zpr%C3%A1va+z+webu\">marek@adf.cz</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":6,"paddingBottom":4}} name={"k5uctombrlq"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-1wy6pf2" style={{"paddingTop":17,"paddingBottom":42}} name={"g2xflcl795"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37992/4d0b7b66dde2422db17acdad223ec20e_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/4d0b7b66dde2422db17acdad223ec20e_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37992/4d0b7b66dde2422db17acdad223ec20e_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/4d0b7b66dde2422db17acdad223ec20e_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37992/4d0b7b66dde2422db17acdad223ec20e_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37992/4d0b7b66dde2422db17acdad223ec20e_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/4d0b7b66dde2422db17acdad223ec20e_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/4d0b7b66dde2422db17acdad223ec20e_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --center el--1" style={{"maxWidth":870,"paddingLeft":1,"paddingRight":0,"backgroundColor":"var(--white)"}} anim={"2"} columns={"1"}>
            
            <ColumnWrapper style={{"marginBottom":1,"paddingBottom":42,"paddingTop":29}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":1332,"marginBottom":0,"paddingBottom":21}} content={"<span style=\"font-style: italic; background-color: rgb(255, 255, 255);\">Využijte náš kontaktní formulář. <br>Rádi se Vám ozveme zpět.</span>"}>
              </Subtitle>

              <ContactForm className="--shape2 --shape3" style={{"maxWidth":800,"paddingLeft":0}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte prosím Vaše jméno","id":"m9p01mjtolzt"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail","id":"ricpnwc2mk1u"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"gwugcyffy6h7"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy","id":"ugagyu7dl8yk"},{"name":"Odeslat","type":"submit","id":"27sg68bpl3d"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":3,"paddingBottom":5}} name={"om34qojua3k"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--left" style={{"marginTop":1,"paddingTop":1,"paddingBottom":2}} name={"qdlxp92xfkn"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"paddingTop":34}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} alt={""} src={"https://cdn.swbpg.com/t/37992/98ffc729964a4fd2bfd66df94c0365e0_s=660x_.png"} use={"page"} href={"/"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/98ffc729964a4fd2bfd66df94c0365e0_s=350x_.png 350w, https://cdn.swbpg.com/t/37992/98ffc729964a4fd2bfd66df94c0365e0_s=660x_.png 660w, https://cdn.swbpg.com/t/37992/98ffc729964a4fd2bfd66df94c0365e0_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":450,"paddingRight":0}}>
              
              <Subtitle className="subtitle-box subtitle-box--center fs--20" content={"Naše partnerská scéna v Praze<br>"}>
              </Subtitle>

              <Image style={{"maxWidth":360}} alt={""} src={"https://cdn.swbpg.com/t/37992/0627045ac587400f994202c5ed4d6131_s=860x_.jpg"} url={"www.studiodva.cz"} use={"url"} href={"www.studiodva.cz"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/0627045ac587400f994202c5ed4d6131_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/0627045ac587400f994202c5ed4d6131_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/0627045ac587400f994202c5ed4d6131_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/0627045ac587400f994202c5ed4d6131_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37992/0627045ac587400f994202c5ed4d6131_s=2000x_.jpg 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"oifxn1kmtb8"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"marginTop":0,"paddingTop":0,"paddingBottom":0}} name={"r8nodztltl"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":2}}>
              
              <Title className="title-box title-box--center fs--12" content={"© 2024 ADF | Kateřina Schauerová, Marek Sucharda"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}